import { YES_NO_UNKNOWN, NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import { getPropertyCommercial, updatePropertyCommercial } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'free_height',
        label: 'Vrije hoogte',
        placeholder: 'Vrije hoogte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'kva',
        label: 'kVA',
        placeholder: 'kVA',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'light_street',
        label: 'Lichtstraten',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'number_of_ports',
        label: 'Aantal poorten',
        placeholder: 'Aantal poorten',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'ride_height',
        label: 'Nokhoogte',
        placeholder: 'Nokhoogte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'rolling_bridge',
        label: 'Rolbrug',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'translatable',
        name: 'rolling_bridge_description',
        label: 'Rolbrug - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'size_of_largest_port',
        label: 'Afmeting grootste poort',
        placeholder: 'Afmeting grootste poort',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'three_phase_electric_power',
        label: '3-fasenelektriciteit',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'usable_office_space',
        label: 'Bruikbare kantooroppervlakte',
        placeholder: 'Bruikbare kantooroppervlakte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'usable_storage_space',
        label: 'Bruikbare opslagoppervlakte',
        placeholder: 'Bruikbare opslagoppervlakte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'loading_docks',
        label: 'Laadkades',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'number_of_loading_docks',
        label: 'Aantal laadkades',
        placeholder: 'Aantal laadkades',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'parking_spaces_required_for_purchase',
        label: 'Aantal parkeerplaatsen verplicht af te nemen',
        placeholder: 'Aantal parkeerplaatsen verplicht af te nemen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      }
    ]
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyCommercial(propertyId)
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van bedrijfstechnisch gegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const response = await updatePropertyCommercial(propertyId, data)
      successModal('De wijzigingen aan de bedrijfstechnische gegevens zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van de bedrijfstechnische gegevens van dit pand. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}

export default {
  heading: 'Bedrijfstechnische gegevens',
  schema,
  actions
}
